<template>
  <v-container>
    <ApiError :errors="errors"></ApiError>
    <v-row>
      <v-col cols="7">
        <v-file-input
          v-model="newUpload"
          v-bind="$attrs"
          placeholder="Click for uploads"
          prepend-icon=""
          prepend-inner-icon="mdi-paperclip"
          :loading="loading"
          :disabled="disabled"
          :accept="acceptedFormats"
          :error-messages="$error.getValidationError(errors, 'fileValidation')"
          @input="$error.clearValidationError(errors, 'fileValidation')"
          @click:clear="onClear"
          outlined
          color="blue"
        >
        </v-file-input>
      </v-col>
      <v-col cols="2">
        <v-btn
          color="primary"
          class="ma-02"
          @click="onUpload()"
          :disabled="disabled || items.existing.length >= limitNumberFile"
        >
          Upload
          <v-icon right>mdi-cloud-upload</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="10" align="center">
        <p>
          {{ placeholder }}
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            Documents
            <v-spacer></v-spacer>
            <v-text-field v-model="search" label="Search" single-line hide-details></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="value.existing"
            :value="value.existing"
            hide-default-footer
            :items-per-page="limitNumberFile"
            item-key
            :search="search"
          >
            <template v-slot:item.originalFilename="{ item }">
              <a target="_blank" rel="noreferrer" :href="item.href">{{ item.originalFilename }}</a>
            </template>

            <template v-slot:item.tag="{ item }">
              <v-text-field
                v-if="item.id === editedItem.id"
                v-model="editedItem.tag"
                :hide-details="true"
                dense
                single-line
              ></v-text-field>
              <span v-else>
                {{ item.tag }}
              </span>
            </template>

            <template v-slot:item.size="{ item }"> {{ readableBytes(item.size) }} </template>

            <template v-slot:item.updatedDate="{ item }">
              {{ item.updatedDate | formatDateFromNow }}
            </template>
            <template v-slot:item.actions="{ item }">
              <div v-if="item.id === editedItem.id">
                <v-icon @click="saveItem">
                  mdi-content-save
                </v-icon>
                <v-icon @click="close">
                  mdi-window-close
                </v-icon>
              </div>
              <div v-else>
                <v-icon v-if="!disabled && allowRemoval" @click="editItem(item)">
                  mdi-pencil
                </v-icon>
                <v-icon v-if="!disabled && allowRemoval" @click="onDeleteItem(item)">
                  mdi-delete
                </v-icon>
              </div>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ApiService from "../../services/api.service";
import ApiError from "../display/ApiError";

export default {
  name: "UploadTable",
  components: {
    ApiError
  },
  props: {
    value: Object,
    disabled: Boolean,
    limitNumberFile: {
      type: Number,
      required: false,
      default: 12
    },
    allowRemoval: {
      type: Boolean,
      default: true
    },
    extraFormats: {
      type: Array,
      default: () => []
    },
    uploadOnBehalfOfParticipantId: Number
  },
  data: () => ({
    loading: false,
    headers: [
      { text: "File name", value: "originalFilename" },
      { text: "Tag", value: "tag" },
      { text: "Content Type", value: "contentType" },
      { text: "Size", value: "size", align: "end" },
      { text: "Actions", value: "actions", sortable: false, align: "center" }
    ],
    newUpload: undefined,
    search: "",
    editedItem: false,
    editedIndex: -1,
    items: {
      existing: [],
      deleted: []
    },
    errors: {}
  }),
  methods: {
    onInput() {
      this.$emit("input", this.items);
    },
    onDeleteItem(item) {
      const index = this.items.existing.indexOf(item);
      let deletedItems = this.items.existing.splice(index, 1);
      deletedItems = deletedItems.reduce((acc, cur) => {
        if (cur.id) {
          acc.push(cur);
        }
        return acc;
      }, []);
      this.items.deleted = this.items.deleted.concat(deletedItems);
      this.$emit("input", this.items);
    },
    editItem(item) {
      this.editedIndex = this.items.existing.indexOf(item);
      this.editedItem = Object.assign({}, item);
    },
    close() {
      this.editedItem = "";
      this.editedIndex = -1;
    },
    saveItem() {
      if (this.editedIndex > -1) {
        this.editedItem.dirty = true;
        Object.assign(this.items.existing[this.editedIndex], this.editedItem);
      }
      this.close();
    },
    onUpload() {
      this.errors = {};
      if (this.newUpload && this.fileValidation(this.newUpload)) {
        let formData = new FormData();
        formData.append("file", this.newUpload);
        if (this.uploadOnBehalfOfParticipantId) {
          formData.append("participantId", this.uploadOnBehalfOfParticipantId);
        }
        this.loading = true;
        ApiService.post("/api/uploads", formData)
          .then(({ data }) => {
            this.items.existing.push(data);
            this.$emit("upload");
            this.$emit("input", this.items);
          })
          .catch(error => {
            this.errors = ApiService.getErrorsFromResponse(error);
          })
          .finally(() => {
            this.loading = false;
          });
        this.newUpload = undefined;
      }
    },
    readableBytes(bytes) {
      var i = Math.floor(Math.log(bytes) / Math.log(1024)),
        sizes = ["B", "KB", "MB", "GB"];

      return (bytes / Math.pow(1024, i)).toFixed(2) * 1 + " " + sizes[i];
    },
    fileValidation(file) {
      this.errors = this.errors || {};
      let defaultExtensions = /(.jpg|.jpeg|.png|.pdf|.doc|.docx|.ppt|.xls|.xlsx|.msg)$/i;
      let extraExtensions =
        this.extraFormats.length > 0 ? new RegExp("(" + this.extraFormats.join("|") + ")$", "i") : null;
      if (file && !defaultExtensions.exec(file.name) && (!extraExtensions || !extraExtensions.exec(file.name))) {
        let apiError = ApiService.createError("File Validation");
        apiError.addValidationError(
          "fileValidation",
          "Acceptable file types: .jpg, .jpeg, .png, .pdf, .doc, .docx, .ppt, .xls, .xlsx, .msg" +
            (this.extraFormats.length > 0 ? ", " + this.extraFormats.join(", ") : "")
        );
        this.errors = apiError;

        return false;
      }
      return true;
    },
    onClear() {
      this.fileValidation();
    }
  },
  computed: {
    placeholder() {
      let defaultFormats = " (.jpg, .jpeg, .png, .pdf, .doc, .docx, .ppt, .xls, .xlsx, .msg";
      let extraFormatsString = this.extraFormats.length > 0 ? ", " + this.extraFormats.join(", ") : "";
      return "Limit " + this.limitNumberFile + defaultFormats + extraFormatsString + ")";
    },
    acceptedFormats() {
      let defaultFormats = ".jpg, .jpeg, .png, .pdf, .doc, .docx, .ppt, .xls, .xlsx, .msg";
      let extraFormatsString = this.extraFormats.length > 0 ? ", " + this.extraFormats.join(", ") : "";
      return defaultFormats + extraFormatsString;
    }
  },
  mounted() {
    this.items.existing = this.value.existing;
  }
};
</script>
